@import "styles/utils.module";
@import "styles/color.module";


.container {
  padding-top: 20px;
}

.titleNickname {
  font-size: 26px;
  line-height: 100%;
  font-weight: 700;
  color: $typo4;
  display: block;
}

@include tablet {
  .container {
    padding-top: 0;
  }

  .titleNickname {
    font-size: 22px;
  }
}

.profileImageWrapper {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
}

@include tablet {
  .profileImageWrapper {
    width: 72px;
    height: 72px;
  }
}

@include mobile {
  .profileImageWrapper {
    width: 68px;
    height: 68px;
    margin-top: 24px;
  }
}

.contentNickname {
  font-weight: bold;
  font-size: 16px;
}

@include tablet {
  .contentNickname {
    font-size: 15px;
  }
}

.badge {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

.creatorSection {

}

@include tablet {
  .creatorSection {
    padding: 0 20px;
  }
}

.contentSection {
  margin-top: 60px;
}

@include tablet {
  .contentSection {
    margin-top: 40px;
    padding: 0 20px;
  }
}

@include mobile {
  .contentSection {
    padding-top: 4px;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.paletteCardWrapper {
  margin-bottom: 32px;
  flex: 0 0 50%;
  padding: 0 0;
  &:nth-child(2n) {
    padding-left: 1.5px;
  }

  &:nth-child(2n+1) {
    padding-right: 1.5px;
  }
}

.introduction {
  font-weight: 500;
  line-height: 140%;
  font-size: 14px;
  color: $typo2;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 8px;
}

.snsSection {
  margin-top: 16px;
  line-height: 0;
}

.snsIconWrapper {
  margin-left: 24px;
  &:first-of-type {
    margin-left: 0;
  }
}

.snsIcon {
  width: 32px;
  height: 32px;
  & > circle {
    fill: $lightBackground;
  }
  & path {
    fill: $typo3;
  }
  p path {
    fill: $typo3;
  }
}

.paletteWrapper {
  padding: 0 16px;
  margin-top: 20px;
}

.sectionTitle {
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  color: $typo4;
}

@include tablet {
  .sectionTitle {
    font-size: 18px;
  }
}

.specialPriceNickname {
  color: $brand;
}

.col {
  margin-bottom: 32px;
  flex: 0 0 50%;
  padding: 0 0;
  &:nth-child(2n) {
    padding-left: 1.5px;

  }
  &:nth-child(2n+1) {
    padding-right: 1.5px;
  }
}
.storeCardWrapper {
  @extend .flexCol;


}

.deleteButton {
  margin-top: 12px;
  border-radius: 5px;
  border: 1px solid $gray300;
  height: 29px;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  font-weight: 600;
  line-height: 100%;
  color: $gray800;
  font-size: 13px;
  cursor: pointer;
}

.topProductsWrapper {

}

.productsWrapper {
}


.specialPriceSection {
  margin-top: 24px;
  margin-bottom: 80px;
  padding: 0 20px;
}

@include mobile {
  .specialPriceSection {
    margin-bottom: 40px;
  }
}

.postProductSection {
  margin-top: 24px;
}


.errorContainer {
  text-align: center;
  margin-top: 60px;
}

.errorIcon {
  width: 60px;
  height: 60px;
}

.errorTitle {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  margin-top: 20px;
  color: $brand;
}

.errorMessage {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  color: $typo2;
}

@include tablet {
  .errorIcon {
    width: 48px;
    height: 48px;
  }

  .errorTitle {
    font-size: 18px;
  }

  .errorMessage {
    font-size: 15px;
  }
}

@include mobile {
  .errorContainer {
    margin-top: 40px;
  }

  .errorIcon {
    width: 40px;
    height: 40px;
  }

  .errorTitle {
    margin-top: 16px;
  }
}

.addText {
  font-size: 15px;
  font-weight: 500;
  color: $typo3;
  text-align: center;
  word-break: keep-all;
  line-height: 140%;
}

.profileEditButton {
  height: 36px;
  border-radius: 5px;
  border: 1px solid $tableBorder;
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 500;
  color: $typo3;
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  @extend .cursorPointer;
}

.profileEditLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 100%;
  color: $typo3;
  margin-bottom: 12px;
  display: block;
}

.profileEditWarn {
  display: block;
  margin-top: 12px;
  font-size: 13px;
  font-weight: 500;
  line-height: 100%;
  color: $typo2;
}

.profileEditSubmitButtonContainer {
  padding: 8px 0 16px;
  margin-top: 40px;
}

.modalScrollContainer {
  height: 100%;
  overflow: auto;
}

.couponContainer {
  @extend .fullWidth;
  @extend .flexRow;
  height: 55px;
  @extend .relative;
  @extend .cursorPointer;
  margin-top: 24px;
}

.couponContentContainer {
  @extend .flexCol;
  @extend .alignItemsStart;
  @extend .justifyContentCenter;
  flex: 1;
  background-color: $gray900;
  border-radius: 5px;
  padding: 0 16px;
}

.couponButtonContainer {
  @extend .flexRow;
  @extend .alignItemsCenter;
  @extend .justifyContentCenter;
  width: 64px;
  background-color: $gray900;
  border-radius: 5px;
}

.couponInfo {
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

.couponExpire {
  margin-top: 6px;
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  color: $gray500;
}

.couponCutter {
  @extend .absolute;
  right: 63px;
  bottom: 14px;
  & > div {
    width: 2px;
    height: 2px;
    border-radius: 999px;
    background-color: $gray200;
    margin-top: 3px;
    &:first-of-type {
      margin-top: 0;
    }
  }
}
